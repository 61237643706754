import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { v4 as uuidv4 } from 'uuid';

import ScheduledTask from './ScheduledTask';
import styles from './index.module.css';

const DEFAULT_INTERVAL_IN_MINUTES = 30;
const { PROD_MODE } = process.env;

const ScheduledUserTasks = ({ tasks, onTaskChange, onTaskDelete }) => {
  const { t } = useTranslation();

  const createNewTask = () => {
    onTaskChange({
      enable: true,
      taskId: uuidv4(),
      scheduleTime: new Date(),
      repeatIntervalInMinutes: DEFAULT_INTERVAL_IN_MINUTES,
      isFinal: false,
    });
  };

  return (
    <div className={styles.root}>
      {tasks.map((task) => (
        <ScheduledTask
          key={task.taskId}
          data={task}
          onTaskChange={onTaskChange}
          onTaskDelete={onTaskDelete}
        />
      ))}
      {!PROD_MODE && (
        <Button
          variant="contained"
          color="primary"
          onClick={createNewTask}
          className={styles.button}
        >
          <AddIcon />
          {t('Add task')}
        </Button>
      )}
    </div>
  );
};

export default ScheduledUserTasks;
