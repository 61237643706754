import React, { useState } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';

import styles from './index.module.css';

export const MAX_CELL_WIDTH = 250;
const MAX_TEXT_LENGTH = 10;

const Truncatable = ({ value }) => {
  const [fullComment, setFullComment] = useState({});

  if (!value) return '';

  const formattedValue = value.replaceAll(',', ', ');

  if (formattedValue?.length < MAX_TEXT_LENGTH) return formattedValue;
  else {
    return (
      <>
        <span
          className={styles.cellContent}
          onClick={(e) =>
            setFullComment({ value: formattedValue, anchorEl: e.currentTarget })
          }
        >
          {formattedValue}
        </span>
        <Popover
          open={!!fullComment?.anchorEl}
          anchorEl={fullComment?.anchorEl}
          onClose={() => setFullComment({ ...fullComment, anchorEl: null })}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          <Typography className={styles.popoverContent}>
            {fullComment?.value || ''}
          </Typography>
        </Popover>
      </>
    );
  }
};

export default Truncatable;
