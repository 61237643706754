import React, { useState } from 'react';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { notifyService } from '@tafs/services/notifications';
import LoadingProgress from '@tafs/components/common/LoadingProgress';
import { uploadFile } from '@tafs/services/api';
import styles from './index.module.css';

const FileUploadButton = ({ url, label, disabled, ...rest }) => {
  const [isUploading, setIsUploading] = useState(false);
  const { t } = useTranslation();

  const selectFile = (e) => {
    const file = e.target.files[0];
    setIsUploading(true);
    uploadFile({ file, url })
      .then(() => notifyService.success(t('File uploaded succesfully')))
      .catch(({ message }) => notifyService.error(message))
      .finally(() => setIsUploading(false));
  };

  return (
    <Button
      color={isUploading ? 'secondary' : 'primary'}
      variant="contained"
      component="label"
      disabled={isUploading || disabled}
      {...rest}
    >
      <span className={isUploading ? styles.textHidden : undefined}>
        {t(label)}
      </span>
      {isUploading && (
        <span className={styles.spinner}>
          <LoadingProgress size={10} />
        </span>
      )}
      <Input
        className={styles.displayNone}
        type="file"
        value=""
        onChange={selectFile}
      />
    </Button>
  );
};

FileUploadButton.defaultProps = {
  label: 'Upload file',
};

FileUploadButton.propTypes = {
  url: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default FileUploadButton;
