import {
  SET_ISLOADED_ALL,
  SET_ISLOADED_DASHBOARD,
  SET_ISLOADED_FLAGGED,
  SET_ISLOADED_KRI,
  SET_ISLOADED_CHART_AA,
} from '@tafs/constants/actions';

const { GPB_MODE } = process.env;

const initialState = {
  dashboard: false,
  flagged: false,
  kri: false,
  chartAa: false,
  all: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_ISLOADED_ALL:
      return {
        dashboard: action.payload,
        flagged: action.payload,
        kri: action.payload,
        chartAa: action.payload,
        all: action.payload,
      };
    case SET_ISLOADED_DASHBOARD:
      return {
        ...state,
        dashboard: action.payload,
        all:
          action.payload &&
          state.kri &&
          state.chartAa &&
          (!GPB_MODE || state.flagged),
      };
    case SET_ISLOADED_FLAGGED:
      return {
        ...state,
        flagged: action.payload,
        all: state.dashboard && action.payload && state.kri && state.chartAa,
      };
    case SET_ISLOADED_KRI:
      return {
        ...state,
        kri: action.payload,
        all:
          state.dashboard &&
          action.payload &&
          state.chartAa &&
          (!GPB_MODE || state.flagged),
      };
    case SET_ISLOADED_CHART_AA:
      return {
        ...state,
        chartAa: action.payload,
        all:
          state.dashboard &&
          state.kri &&
          action.payload &&
          (!GPB_MODE || state.flagged),
      };
    default:
      return state;
  }
}
