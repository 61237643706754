import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';

const CheckboxHeader = ({ api, displayName }) => {
  const [allChecked, setAllChecked] = useState(false);
  const [someChecked, setSomeChecked] = useState(false);
  const { t } = useTranslation();

  const onRowsSelectionChanged = useCallback(() => {
    const pageSize = api.paginationGetPageSize();
    const currentPage = api.paginationGetCurrentPage();
    const currentStartIndex = currentPage * pageSize;
    const nextStartIndex = (currentPage + 1) * pageSize;
    const totalRows = api.paginationGetRowCount();

    const pageCheckedRows = api
      .getSelectedNodes()
      .filter(
        (row) =>
          row.rowIndex >= currentStartIndex && row.rowIndex < nextStartIndex
      ).length;

    const pageTotalRows =
      totalRows - currentStartIndex > pageSize
        ? pageSize
        : totalRows % pageSize;

    setAllChecked(pageCheckedRows === pageTotalRows && pageTotalRows !== 0);
    setSomeChecked(pageCheckedRows > 0);
  }, [api]);

  const toggleHeaderCheckbox = () => {
    const pageSize = api.paginationGetPageSize();
    const currentPage = api.paginationGetCurrentPage();
    const currentStartIndex = currentPage * pageSize;
    const nextStartIndex = (currentPage + 1) * pageSize;
    const totalRows = api.paginationGetRowCount();

    api.forEachNode((node) => {
      if (currentStartIndex <= node.rowIndex && nextStartIndex > node.rowIndex)
        node.setSelected(!allChecked);
    });

    if (totalRows > 0) {
      setAllChecked(!allChecked);
      setSomeChecked(false);
    }
  };

  useEffect(() => {
    api.addEventListener('selectionChanged', onRowsSelectionChanged);
    api.addEventListener('paginationChanged', onRowsSelectionChanged);

    return () => {
      api.removeEventListener('selectionChanged', onRowsSelectionChanged);
      api.removeEventListener('paginationChanged', onRowsSelectionChanged);
    };
  }, [api, onRowsSelectionChanged]);

  return (
    <Tooltip title={allChecked ? t('Deselect all') : t('Select all')}>
      <div>
        <Checkbox
          checked={allChecked}
          indeterminate={someChecked && !allChecked}
          onChange={toggleHeaderCheckbox}
          style={{ marginLeft: '-10px' }}
        />
        {displayName}
      </div>
    </Tooltip>
  );
};

export default CheckboxHeader;
