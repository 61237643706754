import React from 'react';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined';

import styles from './index.module.css';

const ExpansionPanel = ({ children, label }) => (
  <Accordion defaultExpanded className={styles.panel}>
    <AccordionSummary
      className={styles.panelSummary}
      expandIcon={<ExpandMoreIcon />}
    >
      {label}
    </AccordionSummary>
    <AccordionDetails className={styles.panelDetails}>
      {children}
    </AccordionDetails>
  </Accordion>
);

export default ExpansionPanel;
