import React from 'react';
import dateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker as MUIDatePicker,
} from '@material-ui/pickers';

import { localeMap } from '@tafs/i18n/i18nextConfig';
import i18next from 'i18next';

export const DatePicker = (props) => (
  <MuiPickersUtilsProvider
    utils={dateFnsUtils}
    locale={localeMap[i18next.language]}
  >
    <MUIDatePicker {...props} />
  </MuiPickersUtilsProvider>
);
