import React from 'react';
import PropTypes from 'prop-types';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link as RouterLink, withRouter } from 'react-router-dom';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import classnames from 'classnames';
import { withTranslation } from 'react-i18next';

import { NS } from '@tafs/i18n/i18nextConfig';
import { routeConfig } from '@tafs/routeConfig';
import styles from './index.module.css';
import { isUUID } from '@tafs/utils';

const breadcrumbNameMap = routeConfig.reduce((result, item) => {
  const lenBeforeParams = item.path.indexOf(':');
  if (lenBeforeParams > 0) {
    // remove 2 chars of '/:'
    result[item.path.substring(0, lenBeforeParams - 1)] = item.name;
  } else {
    result[item.path] = item.name;
  }
  return result;
}, {});

class RouterBreadcrumbs extends React.Component {
  state = {
    open: true,
  };

  handleClick = () => {
    this.setState((state) => ({ open: !state.open }));
  };

  render() {
    const { location, t } = this.props;
    // Remove ID part for card pages with non-UUID IDs
    const pathnames = (location.pathname.match(/.+?(\/card\/)/) || [
      location.pathname,
    ])[0]
      .split('/')
      .filter((x) => x && !isUUID(x));

    return (
      <Breadcrumbs arial-label="Breadcrumb" className={styles.breadcrumbs}>
        <Link component={RouterLink} to="/" className={classnames(styles.text)}>
          <HomeIcon className={styles.icon} />
          {t('Home')}
        </Link>
        {pathnames.slice(1).map((_value, index) => {
          const last = index === pathnames.length - 2;
          const to = `/${pathnames.slice(0, index + 2).join('/')}`;
          const pathName = breadcrumbNameMap[to];

          // Omit routes that are absent in routeConfig
          if (pathName === undefined) return null;

          return last ? (
            <Typography key={to} className={styles.text}>
              {t(pathName)}
            </Typography>
          ) : (
            <Link
              component={RouterLink}
              to={to}
              key={to}
              className={styles.text}
            >
              {t(pathName)}
            </Link>
          );
        })}
      </Breadcrumbs>
    );
  }
}

RouterBreadcrumbs.propTypes = {
  location: PropTypes.shape({}).isRequired,
};

export default withRouter(withTranslation(NS.NAVIGATION)(RouterBreadcrumbs));
