import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/AddOutlined';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import GQTable from '@tafs/components/common/GQTable';
import styles from './index.module.css';

const WatchListPicker = ({
  open,
  onToggle,
  onOk,
  isSubmitting,
  btnName,
  addIcon,
  clientId,
  ...props
}) => {
  const { t } = useTranslation();
  const [selectedRows, setSelectedRows] = useState([]);

  const workspaceEnum = useSelector((state) => state.app.workspace?.enum);

  const isRowSelectableCondition = (data) => {
    const clients = data?.refCounterpartyEntityList.map(
      (el) => el.counterpartyId
    );
    return clients?.includes(clientId);
  };

  const columnDefs = [
    {
      checkboxSelection: true,
      field: 'id',
      headerName: '№',
      sortable: false,
      filter: false,
      cellRendererFramework: ({ rowIndex }) => rowIndex + 1,
      ignoreOnExport: true,
      pinned: 'left',
      cellStyle: ({ data }) =>
        isRowSelectableCondition(data) && { marginLeft: '28px' },
    },
    { field: 'name' },
    {
      field: 'refCounterpartyEntityList.counterpartyId',
      cellRendererFramework: (params) =>
        params.data.refCounterpartyEntityList
          .map((c) => c.counterpartyId)
          .join(', '),
    },
  ];

  const handleSelectionChange = ({ api }) => {
    const rows = api.selectionController.getSelectedRows();
    setSelectedRows(rows);
  };

  const handleTogglePicker = () => {
    setSelectedRows([]);
    onToggle();
  };

  const getRowClass = ({ data }) =>
    isRowSelectableCondition(data) && styles.rowClassGrey;

  const isRowSelectable = ({ data }) =>
    isRowSelectableCondition(data) ? false : true;

  return (
    <>
      <Button
        className={styles.pickBtn}
        onClick={handleTogglePicker}
        {...props}
      >
        {addIcon && <AddIcon />}
        {t(btnName)}
      </Button>
      <Dialog open={open} onClose={handleTogglePicker} maxWidth="md" fullWidth>
        <DialogTitle>{t('Watch lists')}</DialogTitle>
        <DialogContent>
          <div className={styles.tableContainer}>
            <GQTable
              columnDefs={columnDefs}
              entityType="WatchListMonitorEntityList"
              rowSelection="single"
              onSelectionChanged={handleSelectionChange}
              getRowClass={getRowClass}
              isRowSelectable={isRowSelectable}
              filters={[
                {
                  operator: 'equals',
                  key: 'workspace',
                  value: workspaceEnum,
                },
              ]}
              // Disable export due to unexpected behavior with many-to-many graphql join
              // TODO: remove after hasura is introduced
              disableXlsExport
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleTogglePicker}
            variant="contained"
            color="primary"
          >
            {t('Cancel')}
          </Button>
          <Button
            disabled={selectedRows.length === 0 || isSubmitting}
            onClick={() => onOk(selectedRows)}
            variant="contained"
            color="primary"
          >
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WatchListPicker;
