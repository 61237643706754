import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import { format, subDays } from 'date-fns';

import { forceAlgoRun, getGlobalSummary } from '@tafs/services/api';
import { KBDatePicker } from '@tafs/components/common/pickers/KBDatePicker';
import { notifyService } from '@tafs/services/notifications';
import styles from './index.module.css';

const { LITE_MODE } = process.env;
const DATE_FORMAT = 'yyyy-MM-dd';

const ForceRun = ({ divider }) => {
  const { t } = useTranslation();

  const [runDate, setRunDate] = useState(subDays(new Date(), 1));
  const [isLoading, setIsLoading] = useState(true);

  const { edit, workspace } = useSelector((state) => {
    const { workspace } = state.app;

    return {
      edit:
        state.user.permissions?.[workspace.permission]?.schedule?.analyst_panel
          ?.edit ||
        state.user.permissions?.[workspace.permission]?.schedule?.run_settings
          ?.edit,
      workspace,
    };
  });

  useEffect(() => {
    if (LITE_MODE) {
      getGlobalSummary()
        .then((res) => res?.endDate && setRunDate(res?.endDate))
        .finally(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleForceAlgoRun = () => {
    try {
      const formattedDate = format(runDate, DATE_FORMAT);

      setIsLoading(true);

      forceAlgoRun(formattedDate, workspace.enum)
        .then(() => notifyService.success(t('Algorithms run is queued')))
        .catch(({ message }) => notifyService.error(message))
        .finally(() => setIsLoading(false));
    } catch (error) {
      notifyService.error(t('Invalid date format'));
      return;
    }
  };

  return (
    <ListItem divider={divider} className={styles.root}>
      <KBDatePicker
        fullWidth
        ampm={false}
        variant="inline"
        inputVariant="outlined"
        label={t('Launch date')}
        value={runDate}
        onChange={setRunDate}
        format={DATE_FORMAT}
        disabled={isLoading}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleForceAlgoRun}
        disabled={isLoading || !edit}
      >
        {t('Force run')}
      </Button>
    </ListItem>
  );
};

export default ForceRun;
