export const auditReportTypes = {
  deals: 'deals',
  orders: 'orders',
};

export const auditTabs = {
  byDealOrder: 'byDealOrder',
  trading: 'trading',
  behavioral: 'behavioral',
};

export const columnsTypes = {
  consolidated: 'consolidated',
  tradingDate: 'tradingDate',
  netTurnover: 'netTurnover',
};
