import { format } from 'date-fns';
import { auditReportTypes } from '@tafs/constants/auditReport';

import {
  SET_TRADING_AUDIT_DATA,
  SET_DEAL_ORDER_AUDIT_DATA,
  SET_BEHAVIORAL_AUDIT_DATA,
  SET_AUDIT_OPTIONS,
  RESTORE_AUDIT_INITIAL_OPTIONS,
  INITIALIZE_AUDIT_OPTIONS,
} from '../../constants/actions';

const dateFrom = format(new Date(), 'yyyy-MM-dd');
const dateTo = format(new Date(), 'yyyy-MM-dd');

const getOptions = () => {
  return {
    deals: {
      volumeAssetInDay: 0,
      volumebaseInDay: 0,
      mktVolA: 0,
      mktVolC: 0,
      partTurnoverOfClientInExchangeTurnoverInContract: 0,
      partTurnoverOfClientInExchangeTurnoverInMoney: 0,
      countPartDealsOfClientInExchangeDeals: 0,
      deviationPriceOfClientFromMarket: -1,
    },
    orders: {
      deviationVolumeAssetInDayInContract: 0,
      deviationVolumebaseInDayInContract: 0,
      deviationVolumeassetSumOrdersInContract: 0,
      deviationVolumeassetSumOrdersInMoney: 0,
      relativeCancelledOrdersInMoney: 0,
    },
  };
};

const initialState = {
  trading: {
    dateFrom,
    dateTo,
    selectedTickers: '',
    selectedClient: '',
    options: getOptions(),
  },
  byDealOrder: {
    tableType: auditReportTypes.deals,
    dealOrderId: { idVenue: '', id: '' },
  },
  behavioral: {
    dateFrom,
    dateTo,
    selectedTickers: '',
    selectedClient: '',
    selectedRule: '',
    options: getOptions(),
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_TRADING_AUDIT_DATA:
      return {
        ...state,
        trading: {
          ...state.trading,
          dateFrom: action.payload.dateFrom || state.trading.dateFrom,
          dateTo: action.payload.dateTo || state.trading.dateTo,
          selectedTickers:
            action.payload.selectedTickers || state.trading.selectedTickers,
          selectedClient:
            action.payload.selectedClient || state.trading.selectedClient,
        },
      };
    case SET_DEAL_ORDER_AUDIT_DATA:
      return {
        ...state,
        byDealOrder: {
          ...state.byDealOrder,
          tableType: action.payload.tableType || state.byDealOrder.tableType,
          dealOrderId:
            action.payload.dealOrderId || state.byDealOrder.dealOrderId,
        },
      };
    case SET_BEHAVIORAL_AUDIT_DATA:
      return {
        ...state,
        behavioral: {
          ...state.behavioral,
          dateFrom: action.payload.dateFrom || state.behavioral.dateFrom,
          dateTo: action.payload.dateTo || state.behavioral.dateTo,
          selectedTickers:
            action.payload.selectedTickers || state.behavioral.selectedTickers,
          selectedClient:
            action.payload.selectedClient || state.behavioral.selectedClient,
          selectedRule:
            action.payload.selectedRule || state.behavioral.selectedRule,
        },
      };
    case SET_AUDIT_OPTIONS:
      return {
        ...state,
        [action.payload.auditType]: {
          ...state[action.payload.auditType],
          options: {
            ...state[action.payload.auditType].options,
            deals:
              action.payload.deals ||
              state[action.payload.auditType].options.deals,
            orders:
              action.payload.orders ||
              state[action.payload.auditType].options.orders,
          },
        },
      };
    case RESTORE_AUDIT_INITIAL_OPTIONS:
      return {
        ...state,
        [action.payload.auditType]: {
          ...state[action.payload.auditType],
          options: {
            ...state[action.payload.auditType].options,
            [action.payload.type]:
              initialState[action.payload.auditType].options[
                action.payload.type
              ],
          },
        },
      };
    case INITIALIZE_AUDIT_OPTIONS: {
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          options: getOptions(),
        },
      };
    }
    default:
      return state;
  }
}
