import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ClearIcon from '@material-ui/icons/Clear';

import { KBDateTimePicker } from '@tafs/components/common/pickers/KBDateTimePicker';
import styles from './index.module.css';

const { PROD_MODE } = process.env;

const ScheduledTask = ({ data, onTaskChange, onTaskDelete }) => {
  const { t } = useTranslation();

  const handleTimeChange = (newValue) => {
    onTaskChange({ ...data, scheduleTime: newValue });
  };

  const handleIntervalChange = (e) => {
    onTaskChange({ ...data, repeatIntervalInMinutes: e.target.value });
  };

  const handleCheckboxToggle = (e) => {
    onTaskChange({ ...data, isFinal: e.target.checked });
  };

  return (
    <div className={styles.root}>
      <KBDateTimePicker
        autoOk
        ampm={false}
        variant="inline"
        inputVariant="outlined"
        label={t('Launch time')}
        required={true}
        className={styles.textInput}
        value={data.scheduleTime}
        onChange={handleTimeChange}
        format="yyyy-MM-dd HH:mm"
      />
      <TextField
        label={t('Launch interval, minutes')}
        variant="outlined"
        type="number"
        required={true}
        className={styles.textInput}
        value={data.repeatIntervalInMinutes}
        onChange={handleIntervalChange}
      />
      {!PROD_MODE && (
        <>
          <FormControlLabel
            control={
              <Checkbox
                checked={data.isFinal}
                name="isFinal"
                onChange={handleCheckboxToggle}
              />
            }
            label={t('Final run')}
          />

          <Tooltip title={t('Remove task')}>
            <IconButton onClick={() => onTaskDelete(data.taskId)}>
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default memo(ScheduledTask);
