import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

import ExpansionPanel from '@tafs/components/common/ExpansionPanel';
import ForceRun from './ForceRun';
import ScheduleSetting from './ScheduleSetting';
import DataDeletion from './DataDeletion';

const { LITE_MODE } = process.env;

const SettingsDialog = ({ open, onClose }) => {
  const { t } = useTranslation();
  const { runView, settingsView, deletionView } = useSelector((state) => {
    const { run_settings, schedules_settings, deletion } =
      state.user.permissions?.analytic_ib?.schedule || {};
    return {
      runView: run_settings?.view,
      settingsView: schedules_settings?.view,
      deletionView: deletion?.view,
    };
  });

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" fullWidth>
      <DialogTitle>{t('Settings')}</DialogTitle>
      <DialogContent>
        {LITE_MODE && runView && (
          <ExpansionPanel label={t('Run')}>
            <ForceRun />
          </ExpansionPanel>
        )}
        {LITE_MODE && settingsView && (
          <ExpansionPanel label={t('Schedule setting')}>
            <ScheduleSetting />
          </ExpansionPanel>
        )}
        {deletionView && (
          <ExpansionPanel label={t('Deletion')}>
            <DataDeletion />
          </ExpansionPanel>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SettingsDialog;
