import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { DatePicker } from '@tafs/components/common/pickers/DatePicker';
import { format } from 'date-fns';

import i18next from 'i18next';

class AgDatePicker extends Component {
  state = {
    date: this.props.value,
  };

  getValue = () => this.state.date;

  isPopup = () => true;

  handleDateChange = (date) => {
    this.setState({ date: format(date, 'yyyy-MM-dd') });
  };

  handleOk = () => {
    this.props.stopEditing();
  };

  handleCancel = () => {
    const { value, stopEditing } = this.props;

    this.setState({ date: value }, stopEditing);
  };

  render() {
    const { date } = this.state;
    return (
      <>
        <DatePicker
          variant="static"
          openTo="date"
          value={date}
          onChange={this.handleDateChange}
          format="yyyy-MM-dd"
        />
        <ButtonGroup color="primary" variant="text" fullWidth>
          <Button onClick={this.handleOk}>{i18next.t('OK')}</Button>
          <Button onClick={this.handleCancel}>{i18next.t('Cancel')}</Button>
        </ButtonGroup>
      </>
    );
  }
}

export default AgDatePicker;
