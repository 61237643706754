import {
  RESTORE_CASE_INITIAL_STATE,
  SET_CASE_CHART_DATA,
} from '../../constants/actions';

const initialState = {
  chart: {
    selectedTicker: '',
    startDate: null,
    endDate: null,
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_CASE_CHART_DATA:
      return {
        ...state,
        chart: {
          ...state.chart,
          selectedTicker:
            action.payload.selectedTicker || state.chart.selectedTicker,
          startDate: action.payload.startDate || state.chart.startDate,
          endDate: action.payload.endDate || state.chart.endDate,
        },
      };
    case RESTORE_CASE_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
}
