export const SIGNAL_RULE_GROUPS = ['SIGNAL', 'SIGNAL_MANUAL'];
export const MARKET_MAKER_SIGNALS = 'market_maker_report';
export const MANUAL_SIGNALS = [
  'user_moex_signal',
  'signal_deal_id_venue',
  'signal_order_id_venue',
];

export const signalMoex = 'signals-moex';
export const signalMm = 'signals-mm';
export const signalManual = 'signals-manual';
export const signalRegex = [signalMoex, signalMm, signalManual].join('|');
