import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { useTranslation } from 'react-i18next';

import { NS } from '@tafs/i18n/i18nextConfig';

const ConfirmationDialog = ({
  onCancel,
  onOk,
  open,
  children,
  title,
  maxWidth,
}) => {
  const [isSubmited, setIsSubmited] = useState(false);
  const handleOnOk = () => {
    setIsSubmited(true);
    onOk();
  };

  useEffect(() => {
    if (open) setIsSubmited(false);
  }, [open]);

  const { t } = useTranslation(NS.CONSTANTS);

  return (
    <Dialog
      onClose={onCancel}
      aria-labelledby="confirmation-dialog-title"
      open={open}
      maxWidth={maxWidth || 'xs'}
    >
      <DialogTitle id="confirmation-dialog-title">
        {title || t('Warning')}
      </DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" variant="contained">
          {t('Cancel')}
        </Button>
        <Button
          onClick={handleOnOk}
          color="primary"
          variant="contained"
          disabled={isSubmited}
        >
          {t('OK')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialog.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onOk: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ConfirmationDialog;
