import css from 'dom-css';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Scrollbars from 'react-custom-scrollbars';
import { connect } from 'react-redux';
import { saveScrollPosition } from '@tafs/acs/sideMenu';

// To use, wrap up scrollable element in tags like these: <ShadowScrollbars autoHide style={{height: '700px'}}> ... </ShadowScrollbars>
class ShadowScrollbars extends Component {
  constructor(props, ...rest) {
    super(props, ...rest);
    this.state = {
      scrollTop: 0,
      scrollHeight: 0,
      clientHeight: 0,
    };
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleScrollStop = this.handleScrollStop.bind(this);
  }

  componentDidMount() {
    const { scrollPosition } = this.props;
    if (scrollPosition) this.refs.scrollbars.scrollTop(scrollPosition);
  }

  handleUpdate(values) {
    const { shadowTop, shadowBottom } = this.refs;
    const { scrollTop, scrollHeight, clientHeight } = values;
    const shadowTopOpacity = (1 / 20) * Math.min(scrollTop, 20);
    const bottomScrollTop = scrollHeight - clientHeight;
    const shadowBottomOpacity =
      (1 / 20) * (bottomScrollTop - Math.max(scrollTop, bottomScrollTop - 20));
    css(shadowTop, { opacity: shadowTopOpacity });
    css(shadowBottom, { opacity: shadowBottomOpacity });
  }

  handleScrollStop() {
    const { scrollTop } = this.refs.scrollbars.getValues();
    const { saveScrollPosition, needSaveScroll } = this.props;
    if (needSaveScroll) saveScrollPosition(scrollTop);
  }

  render() {
    const {
      style,
      className,
      scrollPosition,
      saveScrollPosition,
      needSaveScroll,
      ...props
    } = this.props;
    const containerStyle = {
      ...style,
      position: 'relative',
    };
    const shadowTopStyle = {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      height: 10,
      background:
        'linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
    };
    const shadowBottomStyle = {
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      height: 10,
      background:
        'linear-gradient(to top, rgba(0, 0, 0, 0.2) 0%, rgba(0, 0, 0, 0) 100%)',
    };

    return (
      <div style={containerStyle} className={className}>
        <Scrollbars
          ref="scrollbars"
          renderThumbVertical={(props) => (
            <div
              {...props}
              className="rounded thumb-vertical"
              style={{ backgroundColor: '#3d7fb5' }}
            />
          )}
          onUpdate={this.handleUpdate}
          onScrollStop={this.handleScrollStop}
          {...props}
        />
        <div ref="shadowTop" style={shadowTopStyle} />
        <div ref="shadowBottom" style={shadowBottomStyle} />
      </div>
    );
  }
}

ShadowScrollbars.propTypes = {
  style: PropTypes.object,
  needSaveScroll: PropTypes.bool,
};

const mapDispatchToProps = {
  saveScrollPosition,
};

export default connect(null, mapDispatchToProps)(ShadowScrollbars);
