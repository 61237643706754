exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".TruncatableCell_cell-content__3ih8x {\n  display: block;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  cursor: pointer;\n}\n\n.TruncatableCell_popover-content__3bdjV {\n  max-width: 300px;\n  padding: 16px;\n  word-break: break-word;\n}\n", ""]);

// Exports
exports.locals = {
	"cell-content": "TruncatableCell_cell-content__3ih8x",
	"cellContent": "TruncatableCell_cell-content__3ih8x",
	"popover-content": "TruncatableCell_popover-content__3bdjV",
	"popoverContent": "TruncatableCell_popover-content__3bdjV"
};