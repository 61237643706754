exports = module.exports = require("../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ExpansionPanel_panel__2dBCp {\n  margin: 0;\n  width: 100%;\n}\n\n  .ExpansionPanel_panel__2dBCp .ExpansionPanel_panel-summary__2hAfd > div {\n      margin: 0;\n    }\n\n  .ExpansionPanel_panel__2dBCp .ExpansionPanel_panel-summary__2hAfd > div > h6 {\n        margin: 0;\n        padding: 0 !important;\n      }\n\n  .ExpansionPanel_panel__2dBCp .ExpansionPanel_panel-details__1VfmF {\n    display: flex;\n    flex-direction: column;\n  }\n", ""]);

// Exports
exports.locals = {
	"panel": "ExpansionPanel_panel__2dBCp",
	"panel-summary": "ExpansionPanel_panel-summary__2hAfd",
	"panelSummary": "ExpansionPanel_panel-summary__2hAfd",
	"panel-details": "ExpansionPanel_panel-details__1VfmF",
	"panelDetails": "ExpansionPanel_panel-details__1VfmF"
};