import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import Cancel from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ListAltIcon from '@material-ui/icons/ListAlt';
import PauseIcon from '@material-ui/icons/PauseCircleOutline';

import StatusDetails from '../StatusDetails';
import { getStatusDetailsData } from '../StatusDetails/utils';
import LoadingProgress from '@tafs/components/common/LoadingProgress';
import {
  CANCELED,
  EXECUTING,
  FINISHED,
  PAUSED,
  SCHEDULED,
} from '@tafs/constants/tasks/status';
import styles from './index.module.css';
import { NS } from '@tafs/i18n/i18nextConfig';

const TaskRow = ({ record, handleHistoryOpen, isFirstPage }) => {
  const statusReadIds = useSelector((state) => state.status.readIds);
  const { t } = useTranslation(NS.CONSTANTS);

  const unread =
    isFirstPage && !statusReadIds.includes(`${record.id}_${record.updateAt}`);
  let statusIcon = null;
  let statusPrompt = '';

  const details = getStatusDetailsData(record);

  switch (record.jobStatus) {
    case SCHEDULED:
      statusIcon = <AccessTimeIcon />;
      statusPrompt = 'Scheduled';
      if (!record.finishedAt) break;
    /* eslint-disable-next-line no-fallthrough */
    case FINISHED:
      if (record.jobError || details?.errorMessages) {
        statusIcon = <ErrorOutlineIcon className={styles.fail} />;
        statusPrompt = 'Failed';
      } else {
        statusIcon = <CheckCircleOutlineIcon className={styles.success} />;
        statusPrompt = 'Succeeded';
      }
      break;
    case EXECUTING:
      statusIcon = <LoadingProgress size={15} />;
      statusPrompt = 'Executing';
      break;
    case CANCELED:
      statusIcon = <Cancel />;
      statusPrompt = 'Canceled';
      break;
    case PAUSED:
      statusIcon = <PauseIcon />;
      statusPrompt = 'Paused';
      break;
    default:
  }

  return (
    <TableRow
      key={record.id}
      className={classnames(styles.statusRow, { [styles.unreadRow]: unread })}
    >
      <TableCell className={styles.statusTime}>{record.updateAt}</TableCell>
      <TableCell>
        {record.jobName.includes('FineTuningOptimizationTask') ? (
          <>
            {`${t(record.jobName.split('-')[0])}-${
              record.jobName.split('-')[1]
            }`}
          </>
        ) : (
          <>{t(record.jobName)}</>
        )}
      </TableCell>
      <TableCell className={styles.statusIcon}>{statusIcon}</TableCell>
      <TableCell className={styles.statusPrompt}>{t(statusPrompt)}</TableCell>
      <TableCell className={styles.statusDetails}>
        {details && <StatusDetails details={details} />}
      </TableCell>
      <TableCell className={styles.expand}>
        {(record.jobStatus === SCHEDULED || record.repeatInterval) && (
          <Tooltip title={t('View task history')}>
            <IconButton
              size="small"
              onClick={() => handleHistoryOpen(record.id)}
            >
              <ListAltIcon />
            </IconButton>
          </Tooltip>
        )}
      </TableCell>
      <TableCell>{record.user}</TableCell>
    </TableRow>
  );
};

export default TaskRow;
