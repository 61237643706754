import { isObject } from 'lodash';

export const moduleEnum = {
  APP_PERF_MONITORING: 'app_perf_monitoring',
  USER_MANAGEMENT: 'user_management',
  SYSTEM_PROPERTY: 'system_property',
  REF_AUDIT: 'ref_audit',
  USER_AUDIT: 'user_audit',
  DASHBOARD: 'dashboard',
  LIST_OF_RULES: 'list_of_rules',
  INCIDENTS: 'incidents',
  ANALYST_PANEL: 'analyst_panel',
  CUSTOM_RULES: 'custom_rules',
  WATCH_LISTS: 'watch_lists',
  CASE_MANAGEMENT: 'case_management',
  REFERENCE: 'reference',
  LOADER_DATA: 'loader_data',
  SIGNALS: 'signals',
  FINE_TUNING_OTHERS: 'fine_tuning_others',
  OVERLAP: 'overlap',
  ONE_CLICK_AUDIT: 'one_click_audit',
  ROLE_MANAGEMENT: 'role_management',
  RUN_SETTINGS: 'run_settings',
  SCHEDULES_SETTINGS: 'schedules_settings',
  DELETION: 'deletion',
};

export const permissionEnum = {
  EDIT: 'edit',
  VIEW: 'view',
};

export const parsePermissions = (arr, defaultValue = false) =>
  arr.reduce((acc, curr) => {
    curr.split('.').reduce((prev, next, idx, arr) => {
      if (!prev[next]) prev[next] = idx < arr.length - 1 ? {} : defaultValue;
      return prev[next];
    }, acc);
    return acc;
  }, {});

export const stringifyPermissions = (permissionObj, permissionChain = []) => {
  const res = [];

  Object.keys(permissionObj).forEach((key) => {
    const value = permissionObj[key];
    const extendedPermissionChain = [...permissionChain, key];

    if (isObject(value))
      res.push(...stringifyPermissions(value, extendedPermissionChain));
    else if (value) res.push(extendedPermissionChain.join('.'));
  });

  return res;
};

export const isPartOf = (permissionString, userPermissionObj) => {
  const [ws, mode, module, permission] = permissionString.split('.');
  return !!userPermissionObj?.[ws]?.[mode]?.[module]?.[permission];
};
