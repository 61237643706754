import {
  SET_INCIDENT_MANAGEMENT_DATA,
  SET_INCIDENT_NEWS_DATA,
  SET_INCIDENT_CHART_DATA,
  RESTORE_INCIDENT_INITIAL_STATE,
} from '../../constants/actions';

const initialState = {
  management: {
    status: '',
    assignBy: null,
    comment: '',
  },
  news: {
    dateFrom: '',
    dateTo: '',
  },
  chart: {
    selectedTicker: '',
  },
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INCIDENT_MANAGEMENT_DATA:
      const { status, assignBy, comment } = action.payload;
      return {
        ...state,
        management: {
          ...state.management,
          status: status || state.management.status,
          assignBy: assignBy || state.management.assignBy,
          comment: comment !== undefined ? comment : state.management.comment,
        },
      };
    case SET_INCIDENT_NEWS_DATA:
      return {
        ...state,
        news: {
          ...state.news,
          dateFrom: action.payload.dateFrom || state.news.dateFrom,
          dateTo: action.payload.dateTo || state.news.dateTo,
        },
      };
    case SET_INCIDENT_CHART_DATA:
      return {
        ...state,
        chart: {
          ...state.chart,
          selectedTicker:
            action.payload.selectedTicker || state.chart.selectedTicker,
        },
      };
    case RESTORE_INCIDENT_INITIAL_STATE:
      return initialState;
    default:
      return state;
  }
}
