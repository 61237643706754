import React from 'react';
import Button from '@material-ui/core/Button';
import ColumnsIcon from '@material-ui/icons/ViewWeekOutlined';
import FilterIcon from '@material-ui/icons/FilterList';
import { useTranslation } from 'react-i18next';

import { NS } from '@tafs/i18n/i18nextConfig';

import styles from './index.module.css';

const CustomInstrumentsToolPanel = ({ stateKey, api }) => {
  const { t } = useTranslation(NS.AG_GRID);

  const handleResetColumnState = () => {
    api.columnController.resetColumnState();
  };

  const handleResetFilterState = () => {
    api.setFilterModel([]);
    api.setSortModel({});
  };

  return (
    <>
      {!!stateKey && (
        <>
          <Button
            variant="contained"
            onClick={handleResetColumnState}
            fullWidth
            className={styles.button}
          >
            <ColumnsIcon />
            <span className={styles.label}>{t('Reset column state')}</span>
          </Button>
          <Button
            variant="contained"
            onClick={handleResetFilterState}
            fullWidth
            className={styles.button}
          >
            <FilterIcon />
            <span className={styles.label}>{t('Reset filters')}</span>
          </Button>
        </>
      )}
    </>
  );
};

export default CustomInstrumentsToolPanel;
