import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import { format, set } from 'date-fns';

import {
  getBusinessSettings,
  getWatchListById,
  updateBusinessSettings,
} from '@tafs/services/api';
import { notifyService } from '@tafs/services/notifications';
import LoadingProgress from '@tafs/components/common/LoadingProgress';
import { getEpoch, isValidDateTime } from '@tafs/utils';
import { UPLOAD_REF_WORKING_DAY } from '@tafs/constants/api';
import styles from './index.module.css';
import FileUploadButton from '@tafs/components/common/FileUploadButton';
import { KBDateTimePicker } from '@tafs/components/common/pickers/KBDateTimePicker';
import WatchListPicker from '@tafs/components/common/WatchListPicker';
import ForceRun from '../ForceRun';
import ScheduledUserTasks from './ScheduledUserTasks';
import { workspaceObj } from '@tafs/constants/workspaces';

const TIME_FORMAT_SECONDS = 'yyyy-MM-dd HH:mm:ss';
const TIME_FORMAT_MINUTES = 'yyyy-MM-dd HH:mm';

const { GPB_MODE, LITE_MODE, PROD_MODE } = process.env;

const ScheduleSetting = () => {
  const { t } = useTranslation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isWListPickerOpen, setIsWListPickerOpen] = useState(false);
  const [isWListPickerAffiliatedOpen, setIsWListPickerAffiliatedOpen] =
    useState(false);
  const [newsUpload, setNewsUpload] = useState({
    newsScheduleEnable: false,
    newsEmailUser: '',
    newsEmailPassword: '',
    newsScheduleTime: null,
  });
  const [refIssues, setRefIssues] = useState({
    refIssuesScheduleEnable: false,
    refIssuesScheduleTime: null,
  });
  const [refRates, setRefRates] = useState({
    refRatesScheduleEnable: false,
    refRatesScheduleTime: null,
  });
  const [calcUserIncident, setCalcUserIncident] = useState({
    calcUserIncidentScheduleEnable: false,
    calcUserIncidentScheduleTime: null,
  });
  const [calcIncidentWithinDay, setCalcIncidentWithinDay] = useState({
    calcUserIncidentSchedulesEnable: false,
    calcUserIncidentSchedules: [],
  });
  const [orderBook, setOrderBook] = useState({
    orderBookScheduleEnable: false,
    orderBookFtpServer: '',
    orderBookFtpPort: '',
    orderBookFtpUser: '',
    orderBookFtpPassword: '',
    orderBookFtpStartFolder: '',
    orderBookScheduleTime: null,
  });
  const [refPosition, setRefPosition] = useState({
    refPositionScheduleEnable: false,
    refPositionFtpServer: '',
    refPositionFtpPort: '',
    refPositionFtpUser: '',
    refPositionFtpPassword: '',
    refPositionFtpStartFolder: '',
    refPositionScheduleTime: null,
  });
  const [sendReportStats, setSendReportStats] = useState({
    sendReportStatisticScheduleEnable: false,
    sendReportStatisticScheduleIdWatchList: '',
    sendReportStatisticScheduleTime: null,
  });
  const [reportStatScheduleWListName, setReportStatScheduleWListName] =
    useState('');
  const [generationAffiliation, setGenerationAffiliation] = useState({
    isGeneratingAffiliatedDataScheduleEnable: false,
    affiliatedDataScheduleTime: null,
  });
  const [generationAffiliationAndMatrix, setGenerationAffiliationAndMatrix] =
    useState({
      isGeneratingAffiliatedDataAndMatrixScheduleEnable: false,
      affiliatedDataAndMatrixScheduleTime: set(new Date(), {
        hours: 2,
        minutes: 0,
        seconds: 0,
      }),
    });
  const [sendReportAffiliated, setSendReportAffiliated] = useState({
    sendReportAffiliatedScheduleEnable: false,
    sendReportAffiliatedScheduleIdWatchList: '',
    sendReportAffiliatedScheduleTime: null,
  });
  const [reportAffiliatedWListName, setReportAffiliatedWListName] =
    useState('');
  const [limits, setLimits] = useState({
    saveIncidentsWithoutDealsOrOrdersOnReportDateEnable: false,
  });

  const { edit, workspace } = useSelector((state) => {
    const workspace = LITE_MODE ? workspaceObj.ANALYST : state.app.workspace;

    return {
      edit:
        state.user.permissions?.[workspace.permission]?.schedule?.analyst_panel
          ?.edit ||
        state.user.permissions?.[workspace.permission]?.schedule
          ?.schedules_settings?.edit,
      workspace,
    };
  });

  const handleTaskChange = (newValue) => {
    const { calcUserIncidentSchedules } = calcIncidentWithinDay;
    const updatedTaskIndex = calcUserIncidentSchedules.findIndex(
      (tasks) => tasks.taskId === newValue.taskId
    );

    if (updatedTaskIndex === -1) {
      calcUserIncidentSchedules.push(newValue);
    } else {
      calcUserIncidentSchedules[updatedTaskIndex] = newValue;
    }

    setCalcIncidentWithinDay((prevState) => ({
      ...prevState,
      calcUserIncidentSchedules,
    }));
  };

  const handleTaskDelete = (id) => {
    setCalcIncidentWithinDay((prevState) => ({
      ...prevState,
      calcUserIncidentSchedules: prevState.calcUserIncidentSchedules.filter(
        (task) => task.taskId !== id
      ),
    }));
  };

  const handleReportStatisticScheduleWList = (selectedRows) => {
    setSendReportStats((prevState) => ({
      ...prevState,
      sendReportStatisticScheduleIdWatchList: selectedRows[0].id,
    }));
    setIsWListPickerOpen(false);
    setReportStatScheduleWListName(selectedRows[0].name);
  };

  const handleReportAffiliatedWList = (selectedRows) => {
    setSendReportAffiliated((prevState) => ({
      ...prevState,
      sendReportAffiliatedScheduleIdWatchList: selectedRows[0].id,
    }));
    setIsWListPickerAffiliatedOpen(false);
    setReportAffiliatedWListName(selectedRows[0].name);
  };

  const saveData = () => {
    const {
      newsScheduleEnable,
      newsEmailUser,
      newsEmailPassword,
      newsScheduleTime,
    } = newsUpload;
    const { refIssuesScheduleEnable, refIssuesScheduleTime } = refIssues;
    const { refRatesScheduleEnable, refRatesScheduleTime } = refRates;
    const { calcUserIncidentScheduleEnable, calcUserIncidentScheduleTime } =
      calcUserIncident;
    const { calcUserIncidentSchedulesEnable, calcUserIncidentSchedules } =
      calcIncidentWithinDay;
    const {
      orderBookScheduleEnable,
      orderBookScheduleTime,
      orderBookFtpServer,
      orderBookFtpPort,
      orderBookFtpUser,
      orderBookFtpPassword,
      orderBookFtpStartFolder,
    } = orderBook;
    const {
      refPositionScheduleEnable,
      refPositionScheduleTime,
      refPositionFtpServer,
      refPositionFtpPort,
      refPositionFtpUser,
      refPositionFtpPassword,
      refPositionFtpStartFolder,
    } = refPosition;

    const {
      sendReportStatisticScheduleEnable,
      sendReportStatisticScheduleIdWatchList,
      sendReportStatisticScheduleTime,
    } = sendReportStats;

    const {
      sendReportAffiliatedScheduleEnable,
      sendReportAffiliatedScheduleIdWatchList,
      sendReportAffiliatedScheduleTime,
    } = sendReportAffiliated;

    const {
      isGeneratingAffiliatedDataScheduleEnable,
      affiliatedDataScheduleTime,
    } = generationAffiliation;

    const {
      isGeneratingAffiliatedDataAndMatrixScheduleEnable,
      affiliatedDataAndMatrixScheduleTime,
    } = generationAffiliationAndMatrix;

    const { saveIncidentsWithoutDealsOrOrdersOnReportDateEnable } = limits;

    const requiredFields = [];
    const validTimeValues = [];

    if (newsScheduleEnable) {
      requiredFields.push(newsEmailUser, newsEmailPassword, newsScheduleTime);
      validTimeValues.push(newsScheduleTime);
    }
    if (refIssuesScheduleEnable) {
      requiredFields.push(refIssuesScheduleTime);
      validTimeValues.push(refIssuesScheduleTime);
    }
    if (refRatesScheduleEnable) {
      requiredFields.push(refRatesScheduleTime);
      validTimeValues.push(refRatesScheduleTime);
    }
    if (calcUserIncidentScheduleEnable) {
      requiredFields.push(calcUserIncidentScheduleTime);
      validTimeValues.push(calcUserIncidentScheduleTime);
    }
    calcUserIncidentSchedules.forEach((task) =>
      validTimeValues.push(task.scheduleTime)
    );
    if (orderBookScheduleEnable) {
      requiredFields.push(orderBookScheduleTime);
      validTimeValues.push(orderBookScheduleTime);
    }
    if (refPositionScheduleEnable) {
      requiredFields.push(refPositionScheduleTime);
      validTimeValues.push(refPositionScheduleTime);
    }
    if (sendReportStatisticScheduleEnable) {
      requiredFields.push(
        sendReportStatisticScheduleTime,
        sendReportStatisticScheduleIdWatchList
      );
      validTimeValues.push(sendReportStatisticScheduleTime);
    }
    if (sendReportAffiliatedScheduleEnable) {
      requiredFields.push(
        sendReportAffiliatedScheduleTime,
        sendReportAffiliatedScheduleIdWatchList
      );
      validTimeValues.push(sendReportAffiliatedScheduleTime);
    }
    if (isGeneratingAffiliatedDataScheduleEnable) {
      requiredFields.push(affiliatedDataScheduleTime);
      validTimeValues.push(affiliatedDataScheduleTime);
    }

    if (requiredFields.some((field) => !field)) {
      notifyService.error(t('Required text fields must be filled'));
      return;
    }
    if (validTimeValues.some((time) => !isValidDateTime(time))) {
      notifyService.error(t('Invalid time value'));
      return;
    }

    updateBusinessSettings(
      {
        newsScheduleEnable,
        newsEmailUser: newsScheduleEnable ? newsEmailUser : '',
        newsEmailPassword: newsScheduleEnable ? newsEmailPassword : '',
        newsScheduleTime: newsScheduleEnable
          ? format(newsScheduleTime, TIME_FORMAT_SECONDS)
          : null,
        refIssuesScheduleEnable,
        refIssuesScheduleTime: refIssuesScheduleEnable
          ? format(refIssuesScheduleTime, TIME_FORMAT_SECONDS)
          : null,
        refRatesScheduleEnable,
        refRatesScheduleTime: refRatesScheduleEnable
          ? format(refRatesScheduleTime, TIME_FORMAT_SECONDS)
          : null,
        calcUserIncidentScheduleEnable,
        calcUserIncidentScheduleTime: calcUserIncidentScheduleEnable
          ? format(calcUserIncidentScheduleTime, TIME_FORMAT_SECONDS)
          : null,
        calcUserIncidentSchedules: calcUserIncidentSchedules.map((task) => ({
          ...task,
          scheduleTime: format(task.scheduleTime, TIME_FORMAT_SECONDS),
          enable: calcUserIncidentSchedulesEnable,
        })),
        orderBookScheduleEnable,
        orderBookScheduleTime: orderBookScheduleEnable
          ? format(orderBookScheduleTime, TIME_FORMAT_SECONDS)
          : null,
        orderBookFtpServer,
        orderBookFtpPort,
        orderBookFtpUser,
        orderBookFtpPassword,
        orderBookFtpStartFolder,
        refPositionScheduleEnable,
        refPositionScheduleTime: refPositionScheduleEnable
          ? format(refPosition.refPositionScheduleTime, TIME_FORMAT_SECONDS)
          : null,
        refPositionFtpServer,
        refPositionFtpPort,
        refPositionFtpUser,
        refPositionFtpPassword,
        refPositionFtpStartFolder,
        sendReportStatisticScheduleEnable,
        sendReportStatisticScheduleIdWatchList:
          sendReportStatisticScheduleEnable
            ? sendReportStatisticScheduleIdWatchList
            : null,
        sendReportStatisticScheduleTime: sendReportStatisticScheduleEnable
          ? format(sendReportStatisticScheduleTime, TIME_FORMAT_SECONDS)
          : null,
        isGeneratingAffiliatedDataScheduleEnable,
        affiliatedDataScheduleTime: isGeneratingAffiliatedDataScheduleEnable
          ? format(affiliatedDataScheduleTime, TIME_FORMAT_SECONDS)
          : null,
        isGeneratingAffiliatedDataAndMatrixScheduleEnable,
        affiliatedDataAndMatrixScheduleTime:
          isGeneratingAffiliatedDataAndMatrixScheduleEnable
            ? format(affiliatedDataAndMatrixScheduleTime, TIME_FORMAT_SECONDS)
            : null,
        sendReportAffiliatedScheduleEnable,
        sendReportAffiliatedScheduleIdWatchList:
          sendReportAffiliatedScheduleEnable
            ? sendReportAffiliatedScheduleIdWatchList
            : null,
        sendReportAffiliatedScheduleTime: sendReportAffiliatedScheduleEnable
          ? format(sendReportAffiliatedScheduleTime, TIME_FORMAT_SECONDS)
          : null,
        saveIncidentsWithoutDealsOrOrdersOnReportDateEnable,
      },
      workspace.enum
    )
      .then(() => notifyService.success(t('Settings updated successfully')))
      .catch(({ message }) => {
        notifyService.error(message);
      });
  };

  const loadData = useCallback(() => {
    setIsLoaded(false);
    getBusinessSettings(workspace?.enum)
      .then((res) => {
        setIsLoaded(true);
        setNewsUpload({
          newsScheduleEnable: res.newsScheduleEnable,
          newsEmailUser: res.newsEmailUser || '',
          newsEmailPassword: res.newsEmailPassword || '',
          newsScheduleTime: getEpochOrCurrentDate(
            res.newsScheduleEnable,
            res.newsScheduleTime
          ),
        });
        setRefIssues({
          refIssuesScheduleEnable: res.refIssuesScheduleEnable,
          refIssuesScheduleTime: getEpochOrCurrentDate(
            res.refIssuesScheduleEnable,
            res.refIssuesScheduleTime
          ),
        });
        setRefRates({
          refRatesScheduleEnable: res.refRatesScheduleEnable,
          refRatesScheduleTime: getEpochOrCurrentDate(
            res.refRatesScheduleEnable,
            res.refRatesScheduleTime
          ),
        });
        setCalcUserIncident({
          calcUserIncidentScheduleEnable: res.calcUserIncidentScheduleEnable,
          calcUserIncidentScheduleTime: getEpochOrCurrentDate(
            res.calcUserIncidentScheduleEnable,
            res.calcUserIncidentScheduleTime
          ),
        });
        setCalcIncidentWithinDay({
          calcUserIncidentSchedulesEnable:
            !!res.calcUserIncidentSchedules &&
            res.calcUserIncidentSchedules.some((task) => task.enable),
          calcUserIncidentSchedules: res.calcUserIncidentSchedules
            ? res.calcUserIncidentSchedules.map((task) => ({
                ...task,
                scheduleTime: getEpoch(task.scheduleTime),
              }))
            : [],
        });
        setOrderBook({
          orderBookScheduleEnable: res.orderBookScheduleEnable,
          orderBookFtpServer: res.orderBookFtpServer || '',
          orderBookFtpPort: res.orderBookFtpPort || '',
          orderBookFtpUser: res.orderBookFtpUser || '',
          orderBookFtpPassword: res.orderBookFtpPassword || '',
          orderBookFtpStartFolder: res.orderBookFtpStartFolder || '',
          orderBookScheduleTime: getEpochOrCurrentDate(
            res.orderBookScheduleEnable,
            res.orderBookScheduleTime
          ),
        });
        setRefPosition({
          refPositionScheduleEnable: res.refPositionScheduleEnable,
          refPositionFtpServer: res.refPositionFtpServer || '',
          refPositionFtpPort: res.refPositionFtpPort || '',
          refPositionFtpUser: res.refPositionFtpUser || '',
          refPositionFtpPassword: res.refPositionFtpPassword || '',
          refPositionFtpStartFolder: res.refPositionFtpStartFolder || '',
          refPositionScheduleTime: getEpochOrCurrentDate(
            res.refPositionScheduleEnable,
            res.refPositionScheduleTime
          ),
        });
        setSendReportStats({
          sendReportStatisticScheduleEnable:
            res.sendReportStatisticScheduleEnable,
          sendReportStatisticScheduleIdWatchList:
            res?.sendReportStatisticScheduleIdWatchList,
          sendReportStatisticScheduleTime: getEpochOrCurrentDate(
            res.sendReportStatisticScheduleEnable,
            res?.sendReportStatisticScheduleTime
          ),
        });
        setSendReportAffiliated({
          sendReportAffiliatedScheduleEnable:
            res.sendReportAffiliatedScheduleEnable,
          sendReportAffiliatedScheduleIdWatchList:
            res?.sendReportAffiliatedScheduleIdWatchList,
          sendReportAffiliatedScheduleTime: getEpochOrCurrentDate(
            res.sendReportAffiliatedScheduleEnable,
            res?.sendReportAffiliatedScheduleTime
          ),
        });
        setGenerationAffiliation({
          isGeneratingAffiliatedDataScheduleEnable:
            res.isGeneratingAffiliatedDataScheduleEnable,
          affiliatedDataScheduleTime: getEpochOrCurrentDate(
            res.isGeneratingAffiliatedDataScheduleEnable,
            res.affiliatedDataScheduleTime
          ),
        });
        setGenerationAffiliationAndMatrix((prevState) => ({
          ...prevState,
          isGeneratingAffiliatedDataAndMatrixScheduleEnable:
            res?.isGeneratingAffiliatedDataAndMatrixScheduleEnable,
        }));
        setLimits({
          saveIncidentsWithoutDealsOrOrdersOnReportDateEnable:
            res?.saveIncidentsWithoutDealsOrOrdersOnReportDateEnable,
        });
      })
      .catch(({ message }) => notifyService.error(message));
  }, [workspace]);

  const getEpochOrCurrentDate = (isEnabled, value) => {
    return isEnabled ? getEpoch(value) : new Date();
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    getWatchListById(sendReportStats.sendReportStatisticScheduleIdWatchList)
      .then((res) => setReportStatScheduleWListName(res?.name))
      .catch(({ message }) => notifyService.error(message));
  }, [sendReportStats, workspace]);

  useEffect(() => {
    getWatchListById(
      sendReportAffiliated.sendReportAffiliatedScheduleIdWatchList
    )
      .then((res) => setReportAffiliatedWListName(res?.name))
      .catch(({ message }) => notifyService.error(message));
  }, [sendReportAffiliated, workspace]);

  return (
    <>
      {isLoaded ? (
        <CardContent>
          <List className={styles.list}>
            {!PROD_MODE && workspace?.id === workspaceObj.ANALYST.id && (
              <>
                <ListItem divider={!newsUpload.newsScheduleEnable}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={newsUpload.newsScheduleEnable}
                        onChange={(e) => {
                          e.persist();
                          setNewsUpload((prevState) => ({
                            ...prevState,
                            newsScheduleEnable: e.target.checked,
                          }));
                        }}
                        name="newsScheduleEnable"
                      />
                    }
                    label={t('Schedule news download')}
                  />
                </ListItem>
                <Collapse in={newsUpload.newsScheduleEnable}>
                  <ListItem>
                    <TextField
                      fullWidth
                      label="E-mail"
                      type="email"
                      name="newsEmailUser"
                      value={newsUpload.newsEmailUser}
                      onChange={(e) => {
                        e.persist();
                        setNewsUpload((prevState) => ({
                          ...prevState,
                          newsEmailUser: e.target.value,
                        }));
                      }}
                      variant="outlined"
                      disabled={!newsUpload.newsScheduleEnable}
                      required={newsUpload.newsScheduleEnable}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      fullWidth
                      label={t('Password')}
                      type="password"
                      name="newsEmailPassword"
                      value={newsUpload.newsEmailPassword}
                      onChange={(e) => {
                        e.persist();
                        setNewsUpload((prevState) => ({
                          ...prevState,
                          newsEmailPassword: e.target.value,
                        }));
                      }}
                      variant="outlined"
                      disabled={!newsUpload.newsScheduleEnable}
                      required={newsUpload.newsScheduleEnable}
                    />
                  </ListItem>
                  <ListItem divider>
                    <KBDateTimePicker
                      autoOk
                      fullWidth
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      label={t('Launch time')}
                      value={newsUpload.newsScheduleTime}
                      onChange={(date) =>
                        setNewsUpload((prevState) => ({
                          ...prevState,
                          newsScheduleTime: date,
                        }))
                      }
                      format={TIME_FORMAT_MINUTES}
                      disabled={!newsUpload.newsScheduleEnable}
                      required={newsUpload.newsScheduleEnable}
                    />
                  </ListItem>
                </Collapse>
                <ListItem divider={!refIssues.refIssuesScheduleEnable}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={refIssues.refIssuesScheduleEnable}
                        onChange={(e) => {
                          e.persist();
                          setRefIssues((prevState) => ({
                            ...prevState,
                            refIssuesScheduleEnable: e.target.checked,
                          }));
                        }}
                        name="refIssuesScheduleEnable"
                      />
                    }
                    label={t('Schedule issues update')}
                  />
                </ListItem>
                <Collapse in={refIssues.refIssuesScheduleEnable}>
                  <ListItem divider>
                    <KBDateTimePicker
                      fullWidth
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      label={t('Launch time')}
                      value={refIssues.refIssuesScheduleTime}
                      onChange={(date) =>
                        setRefIssues((prevState) => ({
                          ...prevState,
                          refIssuesScheduleTime: date,
                        }))
                      }
                      format={TIME_FORMAT_MINUTES}
                      required={refIssues.refIssuesScheduleEnable}
                    />
                  </ListItem>
                </Collapse>
                <ListItem divider={!refRates.refRatesScheduleEnable}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={refRates.refRatesScheduleEnable}
                        onChange={(e) => {
                          e.persist();
                          setRefRates((prevState) => ({
                            ...prevState,
                            refRatesScheduleEnable: e.target.checked,
                          }));
                        }}
                        name="refRatesScheduleEnable"
                      />
                    }
                    label={t('Schedule rates update')}
                  />
                </ListItem>
                <Collapse in={refRates.refRatesScheduleEnable}>
                  <ListItem divider>
                    <KBDateTimePicker
                      fullWidth
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      label={t('Launch time')}
                      value={refRates.refRatesScheduleTime}
                      onChange={(date) =>
                        setRefRates((prevState) => ({
                          ...prevState,
                          refRatesScheduleTime: date,
                        }))
                      }
                      format={TIME_FORMAT_MINUTES}
                      required={refRates.refRatesScheduleEnable}
                    />
                  </ListItem>
                </Collapse>
              </>
            )}
            {!LITE_MODE && <ForceRun divider={true} />}

            <ListItem
              divider={!calcUserIncident.calcUserIncidentScheduleEnable}
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={calcUserIncident.calcUserIncidentScheduleEnable}
                    onChange={(e) => {
                      e.persist();
                      setCalcUserIncident((prevState) => ({
                        ...prevState,
                        calcUserIncidentScheduleEnable: e.target.checked,
                      }));
                    }}
                    name="calcUserIncidentScheduleEnable"
                  />
                }
                label={t('Schedule algorithms run')}
              />
            </ListItem>
            <Collapse in={calcUserIncident.calcUserIncidentScheduleEnable}>
              <ListItem divider>
                <KBDateTimePicker
                  fullWidth
                  ampm={false}
                  variant="inline"
                  inputVariant="outlined"
                  label={t('Launch time')}
                  value={calcUserIncident.calcUserIncidentScheduleTime}
                  onChange={(date) =>
                    setCalcUserIncident((prevState) => ({
                      ...prevState,
                      calcUserIncidentScheduleTime: date,
                    }))
                  }
                  format={TIME_FORMAT_MINUTES}
                  required={calcUserIncident.calcUserIncidentScheduleEnable}
                />
              </ListItem>
            </Collapse>
            {workspace?.id === workspaceObj.ANALYST.id && (
              <>
                {!LITE_MODE && (
                  <>
                    <ListItem
                      divider={
                        !calcIncidentWithinDay.calcUserIncidentSchedulesEnable
                      }
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={
                              calcIncidentWithinDay.calcUserIncidentSchedulesEnable
                            }
                            onChange={(e) => {
                              e.persist();
                              setCalcIncidentWithinDay((prevState) => ({
                                ...prevState,
                                calcUserIncidentSchedulesEnable:
                                  e.target.checked,
                              }));
                            }}
                            name="calcUserIncidentSchedulesEnable"
                          />
                        }
                        label={t('Schedule algorithms intraday run')}
                      />
                    </ListItem>
                    <Collapse
                      in={calcIncidentWithinDay.calcUserIncidentSchedulesEnable}
                    >
                      <ListItem divider>
                        <ScheduledUserTasks
                          tasks={
                            calcIncidentWithinDay.calcUserIncidentSchedules
                          }
                          onTaskChange={handleTaskChange}
                          onTaskDelete={handleTaskDelete}
                        />
                      </ListItem>
                    </Collapse>
                  </>
                )}
                <ListItem divider={!orderBook.orderBookScheduleEnable}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={orderBook.orderBookScheduleEnable}
                        onChange={(e) => {
                          e.persist();
                          setOrderBook((prevState) => ({
                            ...prevState,
                            orderBookScheduleEnable: e.target.checked,
                          }));
                        }}
                        name="orderBookScheduleEnable"
                      />
                    }
                    label={t('Schedule order book update')}
                  />
                </ListItem>
                <Collapse in={orderBook.orderBookScheduleEnable}>
                  <ListItem>
                    <KBDateTimePicker
                      fullWidth
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      label={t('Launch time')}
                      value={orderBook.orderBookScheduleTime}
                      onChange={(date) =>
                        setOrderBook((prevState) => ({
                          ...prevState,
                          orderBookScheduleTime: date,
                        }))
                      }
                      format={TIME_FORMAT_MINUTES}
                      required={orderBook.orderBookScheduleEnable}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      fullWidth
                      label={t('FTP Server')}
                      type="text"
                      name="orderBookFtpServer"
                      value={orderBook.orderBookFtpServer}
                      onChange={(e) => {
                        e.persist();
                        setOrderBook((prevState) => ({
                          ...prevState,
                          orderBookFtpServer: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      fullWidth
                      label={t('FTP Port')}
                      type="text"
                      name="orderBookFtpPort"
                      value={orderBook.orderBookFtpPort}
                      onChange={(e) => {
                        e.persist();
                        setOrderBook((prevState) => ({
                          ...prevState,
                          orderBookFtpPort: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      fullWidth
                      label={t('FTP User')}
                      type="text"
                      name="orderBookFtpUser"
                      value={orderBook.orderBookFtpUser}
                      onChange={(e) => {
                        e.persist();
                        setOrderBook((prevState) => ({
                          ...prevState,
                          orderBookFtpUser: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label={t('FTP Password')}
                      type="password"
                      name="orderBookFtpPassword"
                      value={orderBook.orderBookFtpPassword}
                      onChange={(e) => {
                        e.persist();
                        setOrderBook((prevState) => ({
                          ...prevState,
                          orderBookFtpPassword: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                  <ListItem divider>
                    <TextField
                      fullWidth
                      label={t('FTP Folder')}
                      type="text"
                      name="orderBookFtpStartFolder"
                      value={orderBook.orderBookFtpStartFolder}
                      onChange={(e) => {
                        e.persist();
                        setOrderBook((prevState) => ({
                          ...prevState,
                          orderBookFtpStartFolder: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                </Collapse>
                <ListItem divider={!refPosition.refPositionScheduleEnable}>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={refPosition.refPositionScheduleEnable}
                        onChange={(e) => {
                          e.persist();
                          setRefPosition((prevState) => ({
                            ...prevState,
                            refPositionScheduleEnable: e.target.checked,
                          }));
                        }}
                        name="refPositionScheduleEnable"
                      />
                    }
                    label={t('Schedule position reference update')}
                  />
                </ListItem>
                <Collapse in={refPosition.refPositionScheduleEnable}>
                  <ListItem>
                    <KBDateTimePicker
                      fullWidth
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      label={t('Launch time')}
                      value={refPosition.refPositionScheduleTime}
                      format={TIME_FORMAT_MINUTES}
                      onChange={(date) =>
                        setRefPosition((prevState) => ({
                          ...prevState,
                          refPositionScheduleTime: date,
                        }))
                      }
                      required={refPosition.refPositionScheduleEnable}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      fullWidth
                      label={t('FTP Server')}
                      type="text"
                      name="refPositionFtpServer"
                      value={refPosition.refPositionFtpServer}
                      onChange={(e) => {
                        e.persist();
                        setRefPosition((prevState) => ({
                          ...prevState,
                          refPositionFtpServer: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      fullWidth
                      label={t('FTP Port')}
                      type="text"
                      name="refPositionFtpPort"
                      value={refPosition.refPositionFtpPort}
                      onChange={(e) => {
                        e.persist();
                        setRefPosition((prevState) => ({
                          ...prevState,
                          refPositionFtpPort: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      fullWidth
                      label={t('FTP User')}
                      type="text"
                      name="refPositionFtpUser"
                      value={refPosition.refPositionFtpUser}
                      onChange={(e) => {
                        e.persist();
                        setRefPosition((prevState) => ({
                          ...prevState,
                          refPositionFtpUser: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      fullWidth
                      autoComplete="off"
                      label={t('FTP Password')}
                      type="password"
                      name="refPositionFtpPassword"
                      value={refPosition.refPositionFtpPassword}
                      onChange={(e) => {
                        e.persist();
                        setRefPosition((prevState) => ({
                          ...prevState,
                          refPositionFtpPassword: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                  <ListItem divider>
                    <TextField
                      fullWidth
                      label={t('FTP Folder')}
                      type="text"
                      name="refPositionFtpStartFolder"
                      value={refPosition.refPositionFtpStartFolder}
                      onChange={(e) => {
                        e.persist();
                        setRefPosition((prevState) => ({
                          ...prevState,
                          refPositionFtpStartFolder: e.target.value,
                        }));
                      }}
                      variant="outlined"
                    />
                  </ListItem>
                </Collapse>
              </>
            )}
            <ListItem
              divider={!sendReportStats.sendReportStatisticScheduleEnable}
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={sendReportStats.sendReportStatisticScheduleEnable}
                    onChange={(e) => {
                      e.persist();
                      setSendReportStats((prevState) => ({
                        ...prevState,
                        sendReportStatisticScheduleEnable: e.target.checked,
                      }));
                    }}
                    name="sendReportStatisticScheduleEnable"
                  />
                }
                label={t('Send connection statistics report')}
              />
            </ListItem>
            <Collapse in={sendReportStats.sendReportStatisticScheduleEnable}>
              <ListItem>
                <WatchListPicker
                  className={styles.pickerButton}
                  fullWidth
                  variant="outlined"
                  open={isWListPickerOpen}
                  onToggle={() =>
                    setIsWListPickerOpen(
                      (isWListPickerOpen) => !isWListPickerOpen
                    )
                  }
                  onOk={handleReportStatisticScheduleWList}
                  isSubmitting={false}
                  btnName={
                    reportStatScheduleWListName
                      ? reportStatScheduleWListName
                      : '-- Select watch list --'
                  }
                />
              </ListItem>
              <ListItem divider>
                <KBDateTimePicker
                  autoOk
                  fullWidth
                  ampm={false}
                  variant="inline"
                  inputVariant="outlined"
                  label={t('Launch time')}
                  value={sendReportStats?.sendReportStatisticScheduleTime}
                  onChange={(date) =>
                    setSendReportStats((prevState) => ({
                      ...prevState,
                      sendReportStatisticScheduleTime: date,
                    }))
                  }
                  format={TIME_FORMAT_MINUTES}
                  disabled={!sendReportStats.sendReportStatisticScheduleEnable}
                  required={sendReportStats.sendReportStatisticScheduleEnable}
                />
              </ListItem>
            </Collapse>
            {!LITE_MODE && workspace?.id === workspaceObj.ANALYST.id && (
              <>
                <ListItem
                  divider={
                    !generationAffiliation.isGeneratingAffiliatedDataScheduleEnable
                  }
                >
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={
                          generationAffiliation.isGeneratingAffiliatedDataScheduleEnable
                        }
                        onChange={(e) => {
                          e.persist();
                          setGenerationAffiliation((prevState) => ({
                            ...prevState,
                            isGeneratingAffiliatedDataScheduleEnable:
                              e.target.checked,
                          }));
                        }}
                        name="isGeneratingAffiliatedDataScheduleEnable"
                      />
                    }
                    label={t('Schedule affiliation calculation')}
                  />
                </ListItem>
                <Collapse
                  in={
                    generationAffiliation.isGeneratingAffiliatedDataScheduleEnable
                  }
                >
                  <ListItem divider>
                    <KBDateTimePicker
                      autoOk
                      fullWidth
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      label={t('Launch time')}
                      value={generationAffiliation.affiliatedDataScheduleTime}
                      onChange={(date) =>
                        setGenerationAffiliation((prevState) => ({
                          ...prevState,
                          affiliatedDataScheduleTime: date,
                        }))
                      }
                      format={TIME_FORMAT_MINUTES}
                      disabled={
                        !generationAffiliation.isGeneratingAffiliatedDataScheduleEnable
                      }
                      required={
                        generationAffiliation.isGeneratingAffiliatedDataScheduleEnable
                      }
                    />
                  </ListItem>
                </Collapse>
              </>
            )}
            {!GPB_MODE && (
              <>
                <ListItem
                  divider={
                    !generationAffiliationAndMatrix.isGeneratingAffiliatedDataAndMatrixScheduleEnable
                  }
                >
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={
                          generationAffiliationAndMatrix.isGeneratingAffiliatedDataAndMatrixScheduleEnable
                        }
                        onChange={(e) => {
                          e.persist();
                          setGenerationAffiliationAndMatrix((prevState) => ({
                            ...prevState,
                            isGeneratingAffiliatedDataAndMatrixScheduleEnable:
                              e.target.checked,
                          }));
                        }}
                        name="isGeneratingAffiliatedDataAndMatrixScheduleEnable"
                      />
                    }
                    label={t('Schedule affiliation and risk-model calculation')}
                  />
                </ListItem>
                <Collapse
                  in={
                    generationAffiliationAndMatrix.isGeneratingAffiliatedDataAndMatrixScheduleEnable
                  }
                >
                  <ListItem divider>
                    <KBDateTimePicker
                      autoOk
                      fullWidth
                      ampm={false}
                      variant="inline"
                      inputVariant="outlined"
                      label={t('Launch time')}
                      value={
                        generationAffiliationAndMatrix.affiliatedDataAndMatrixScheduleTime
                      }
                      format={TIME_FORMAT_MINUTES}
                      disabled={true}
                    />
                  </ListItem>
                </Collapse>
              </>
            )}
            <ListItem
              divider={!sendReportAffiliated.sendReportAffiliatedScheduleEnable}
            >
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    checked={
                      sendReportAffiliated.sendReportAffiliatedScheduleEnable
                    }
                    onChange={(e) => {
                      e.persist();
                      setSendReportAffiliated((prevState) => ({
                        ...prevState,
                        sendReportAffiliatedScheduleEnable: e.target.checked,
                      }));
                    }}
                    name="sendReportAffiliatedScheduleEnable"
                  />
                }
                label={t('Send affiliation report')}
              />
            </ListItem>
            <Collapse
              in={sendReportAffiliated.sendReportAffiliatedScheduleEnable}
            >
              <ListItem>
                <WatchListPicker
                  className={styles.pickerButton}
                  fullWidth
                  variant="outlined"
                  open={isWListPickerAffiliatedOpen}
                  onToggle={() =>
                    setIsWListPickerAffiliatedOpen(
                      (isWListPickerAffiliatedOpen) =>
                        !isWListPickerAffiliatedOpen
                    )
                  }
                  onOk={handleReportAffiliatedWList}
                  isSubmitting={false}
                  btnName={
                    reportAffiliatedWListName
                      ? reportAffiliatedWListName
                      : '-- Select watch list --'
                  }
                />
              </ListItem>
              <ListItem divider>
                <KBDateTimePicker
                  autoOk
                  fullWidth
                  ampm={false}
                  variant="inline"
                  inputVariant="outlined"
                  label={t('Launch time')}
                  value={sendReportAffiliated?.sendReportAffiliatedScheduleTime}
                  onChange={(date) =>
                    setSendReportAffiliated((prevState) => ({
                      ...prevState,
                      sendReportAffiliatedScheduleTime: date,
                    }))
                  }
                  format={TIME_FORMAT_MINUTES}
                  disabled={
                    !sendReportAffiliated.sendReportAffiliatedScheduleEnable
                  }
                  required={
                    sendReportAffiliated.sendReportAffiliatedScheduleEnable
                  }
                />
              </ListItem>
            </Collapse>
            {!LITE_MODE && workspace?.id === workspaceObj.ANALYST.id && (
              <>
                <ListItem
                  divider={
                    limits.saveIncidentsWithoutDealsOrOrdersOnReportDateEnable
                  }
                >
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={
                          limits.saveIncidentsWithoutDealsOrOrdersOnReportDateEnable
                        }
                        onChange={(e) => {
                          e.persist();
                          setLimits((prevState) => ({
                            ...prevState,
                            saveIncidentsWithoutDealsOrOrdersOnReportDateEnable:
                              e.target.checked,
                          }));
                        }}
                        name="saveIncidentsWithoutDealsOrOrdersOnReportDateEnable"
                      />
                    }
                    label={t(
                      'Save incidents without deals and orders on report date'
                    )}
                  />
                </ListItem>
                <Collapse
                  in={
                    !limits.saveIncidentsWithoutDealsOrOrdersOnReportDateEnable
                  }
                >
                  <ListItem divider>
                    <Paper className={styles.disclaimer}>
                      <WarningIcon />
                      {t(
                        'By turning off this option you confirm that incidents without deals and orders will not be saved to the database'
                      )}
                    </Paper>
                  </ListItem>
                </Collapse>
              </>
            )}
          </List>
        </CardContent>
      ) : (
        <LoadingProgress />
      )}
      <CardActions className={styles.cardActions}>
        <Button
          variant="contained"
          color="primary"
          onClick={saveData}
          disabled={!isLoaded || !edit}
        >
          {t('Apply')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={loadData}
          disabled={!isLoaded}
        >
          {t('Reset changes')}
        </Button>
        {GPB_MODE && (
          <FileUploadButton
            url={UPLOAD_REF_WORKING_DAY}
            label="Upload working days"
            disabled={!edit}
          />
        )}
      </CardActions>
    </>
  );
};

export default ScheduleSetting;
