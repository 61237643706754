import React from 'react';
import dateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from '@material-ui/pickers';

import { localeMap } from '@tafs/i18n/i18nextConfig';
import i18next from 'i18next';

export const KBDateTimePicker = (props) => (
  <MuiPickersUtilsProvider
    utils={dateFnsUtils}
    locale={localeMap[i18next.language]}
  >
    <KeyboardDateTimePicker
      invalidDateMessage={i18next.t('Invalid date format')}
      {...props}
    />
  </MuiPickersUtilsProvider>
);
