import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

const AgSelect =
  (editable, nullable) =>
  ({ setValue, value }) =>
    editable ? (
      <Select
        style={{ paddingTop: 0, marginLeft: 12 }}
        color="primary"
        value={value === null ? 'null' : value}
        onChange={(e) => setValue(e.target.value)}
      >
        <MenuItem value={'true'}>true</MenuItem>
        <MenuItem value={'false'}>false</MenuItem>
        {nullable && <MenuItem value={'null'}>null</MenuItem>}
      </Select>
    ) : value == null ? (
      ''
    ) : (
      value.toString()
    );

export default AgSelect;
