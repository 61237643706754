import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Popover from '@material-ui/core/Popover';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { getWorkspacePath, workspaceObj } from '@tafs/constants/workspaces';
import { postUrl } from '@tafs/services/api';
import styles from './index.module.css';
import { notifyService } from '@tafs/services/notifications';
import ConfirmDialog from '@tafs/components/common/ConfirmDialog';
import { UPLOAD_PAGE } from '@tafs/constants/routes';

const DataDeletionRow = ({ data }) => {
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleDelete = () => {
    if (data?.url) {
      setIsLoading(true);
      postUrl(data.url)
        .then((res) => {
          if (res) {
            notifyService.warn(t(res));
          } else {
            notifyService.success(
              <>
                {t('Data deleted successfully. To upload data go to page')}{' '}
                <Link to={getWorkspacePath(workspaceObj.ANALYST, UPLOAD_PAGE)}>
                  {t('upload data')}
                </Link>
              </>
            );
          }
        })
        .catch(({ message }) => notifyService.error(message))
        .finally(() => {
          setIsLoading(false);
          setIsConfirmDialogOpen(false);
        });
    }
  };

  const getDetailsMessage = () => (
    <div className={styles.popover}>
      {t(data.details)
        .split('\n')
        .map((str) => (
          <Typography>{str}</Typography>
        ))}
    </div>
  );

  return (
    <TableRow>
      <TableCell className={styles.resizeFont}>{t(data.label)}</TableCell>
      <TableCell>
        <Tooltip title={t('View details')} enterDelay={300}>
          <IconButton
            size="small"
            onClick={(e) => setPopoverAnchor(e.currentTarget)}
          >
            <InfoIcon />
          </IconButton>
        </Tooltip>
        <Popover
          open={!!popoverAnchor}
          anchorEl={popoverAnchor}
          onClose={() => setPopoverAnchor(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >
          {getDetailsMessage()}
        </Popover>
      </TableCell>
      <TableCell>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsConfirmDialogOpen(true)}
          disabled={!data?.url || isLoading}
        >
          {t('Delete')}
        </Button>
      </TableCell>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        onOk={handleDelete}
        onCancel={() => setIsConfirmDialogOpen(false)}
      >
        {getDetailsMessage()}
      </ConfirmDialog>
    </TableRow>
  );
};

export default DataDeletionRow;
