exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".ScheduleSetting_card-actions__BALNi {\n  padding-left: 32px;\n  padding-bottom: 32px;\n}\n\n.ScheduleSetting_list__boU2Q {\n  margin-right: auto;\n  margin-left: auto;\n}\n\n.ScheduleSetting_list__boU2Q .ScheduleSetting_picker-button__QUt7t {\n    padding: 16px;\n  }\n\n.ScheduleSetting_list__boU2Q .ScheduleSetting_disclaimer__3u_-0 {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    grid-gap: 8px;\n    gap: 8px;\n    padding: 8px;\n    color: #614b15;\n    background-color: #fff7e6;\n  }\n", ""]);

// Exports
exports.locals = {
	"card-actions": "ScheduleSetting_card-actions__BALNi",
	"cardActions": "ScheduleSetting_card-actions__BALNi",
	"list": "ScheduleSetting_list__boU2Q",
	"picker-button": "ScheduleSetting_picker-button__QUt7t",
	"pickerButton": "ScheduleSetting_picker-button__QUt7t",
	"disclaimer": "ScheduleSetting_disclaimer__3u_-0"
};