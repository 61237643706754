import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';

import {
  DELETE_ALL_DATA_URL,
  DELETE_DATA_INCIDENTS_URL,
  DELETE_DATA_ISSUES_URL,
  DELETE_DATA_COUNTERPARTY_URL,
  DELETE_DATA_INSIDERS_URL,
  DELETE_DATA_STAFF_URL,
  DELETE_DATA_MKT_URL,
  DELETE_DATA_MKT_DAYS_URL,
  DELETE_DATA_POSITION_URL,
  DELETE_DATA_INDEXES_URL,
  DELETE_DATA_FIXING_INTEREST_URL,
} from '@tafs/constants/api';
import DataDeletionRow from './DataDeletionRow';

const items = [
  {
    label: 'Factory reset',
    details:
      'This operation completely deletes all deals, orders, signals, incidents, cases, watch lists, affiliation data, risk matrices, clients, insiders, market data, client positions and fixing data',
    url: DELETE_ALL_DATA_URL,
  },
  {
    label: 'Delete basic data',
    details:
      'This operation completely deletes all incidents, cases, risk matrices and affiliation data',
    url: DELETE_DATA_INCIDENTS_URL,
  },
  {
    label: 'Delete issues data',
    details: 'This operation completely deletes Issues reference data',
    url: DELETE_DATA_ISSUES_URL,
  },
  {
    label: 'Delete counterparty data',
    details: 'This operation completely deletes Counterparty reference data',
    url: DELETE_DATA_COUNTERPARTY_URL,
  },
  {
    label: 'Delete insiders data',
    details: 'This operation completely deletes Insiders reference data',
    url: DELETE_DATA_INSIDERS_URL,
  },
  {
    label: 'Delete staff data',
    details: 'This operation completely deletes Staff reference data',
    url: DELETE_DATA_STAFF_URL,
  },
  {
    label: 'Delete market data',
    details: 'This operation completely deletes Market reference data',
    url: DELETE_DATA_MKT_URL,
  },
  {
    label: 'Delete market days data',
    details: 'This operation completely deletes Market days reference data',
    url: DELETE_DATA_MKT_DAYS_URL,
  },
  {
    label: 'Delete client positions data',
    details: 'This operation completely deletes Client positions data',
    url: DELETE_DATA_POSITION_URL,
  },
  {
    label: 'Delete indexes data',
    details: 'This operation completely deletes Indexes reference data',
    url: DELETE_DATA_INDEXES_URL,
  },
  {
    label: 'Delete fixing interest data',
    details: 'This operation completely deletes Fixing interest reference data',
    url: DELETE_DATA_FIXING_INTEREST_URL,
  },
];

const DataDeletion = () => (
  <Table>
    <TableBody>
      {items.map((item) => (
        <DataDeletionRow key={item.label} data={item} />
      ))}
    </TableBody>
  </Table>
);

export default DataDeletion;
